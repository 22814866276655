<template>
  <v-main class="home">
    <Login />
  </v-main>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'Home',
  components: {
    Login
  },
  computed: {
    user () {
      return this.$store.state.user 
    },
  },
  watch: {
    '$store.state.user': function() {
      if (this.user){
        this.$router.push({name: "galleries"})
      }
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
.home {
  background: url("/Galleryland-background.jpg");
  background-size: cover;
  top:0px;
  bottom:0px;
  position: fixed;
  width:100%;
}
</style>
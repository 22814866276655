<template>

  <v-row class="pa-3" v-if="value.meta">
    <v-toolbar>
        <v-menu v-if="currentArt" offset-y
          :close-on-content-click="false"
            :nudge-width="200"
            v-model="popupMenu"
            >
           <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              
              v-bind="attrs"
              v-on="on"
              
          
            >
              {{currentArt.name || (currentArt.meta && currentArt.meta.name)}} <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
         <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                v-if="currentArt.thumbnails"
                :src="currentArt.thumbnails.x100"
              >
              <img
                v-if="currentArt.image"
                :src="currentArt.image"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{currentArt.name || (currentArt.nft && currentArt.nft.name)}}</v-list-item-title>
              <v-list-item-subtitle v-if="currentArt.artist">{{currentArt.artist}}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="currentArt.meta">{{currentArt.meta.name}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
             
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
      
            <v-list-item-action>
              <v-switch
                v-model="currentArt.frame"

              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Frame</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="pt-10">
                <v-slider label="Size" min=10 max=200 step=5 v-model="currentArt.size" hint="Size % from default" persistent-hint
            thumb-label="always"></v-slider>
            </v-list-item-title>
          </v-list-item>
            <v-list-item>
            <v-list-item-title class="pt-10">
              <v-slider label="Vertical Offset" min=-3 max=10 step=0.2 v-model="currentArt.heightOffset"
            hint="Offset from default height" persistent-hint thumb-label="always"></v-slider>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
      

            <v-list-item-title>Position ID: {{currentArt.frameId}}</v-list-item-title>
          </v-list-item>

        </v-list>

        <v-card-actions>
            <v-btn
            text
            @click="openEditor()"
          >
            Open Editor
          </v-btn>
            <v-btn
            text
            color="red"
            @click="removeArt()"
          >
            Remove
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="popupMenu = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveArt()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-menu>
      <v-spacer></v-spacer>
       <v-btn
            
            @click="reload()"
          >
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
     </v-toolbar> 
    <v-col cols=12>

      <iframe v-if="iframe" ref="iframe" :src="iframe" frameborder=0 width="100%" height="600px"></iframe>
    </v-col>
  </v-row>
</template>  


<script>

//import PA from '@proveanything/base'
//import EventBus from '@/components/EventBus'
import PA from '@proveanything/base'

export default {
  name: "CharacterView",
  components: {
  },
  props: {
    proofGroup: Object,
    value: Object,
    currentArt: Object,
    documents: Array,
    nfts: Array,
    tab: Number
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isDark() {
      return this.$vuetify.theme.dark
    },
  },
  watch: {
    currentArt () {
      if (this.tab == 2)
        this.popupMenu = true;
      this.moveToCurrentArt()
    }
  },
  data: () => ({
    iframe: null,
    popupMenu: false
  }),
  async mounted() {

    //EventBus.$on('uploadProgress', async () => {
    //  this.$forceUpdate()
    //});    
//    this.iframe = `https://viewer.galleryland.xyz/gallery/${this.proofGroup.site}/${this.proofGroup.id}/${this.value.tokenId}`

    this.iframe = `https://viewer.galleryland.xyz/gallery/${this.proofGroup.site}/${this.proofGroup.id}/${this.value.tokenId}?editor=1&autofill=0`
   // this.iframe = `http://127.0.0.1:8082/gallery/${this.proofGroup.site}/${this.proofGroup.id}/${this.value.tokenId}?editor=1&autofill=0`

    addEventListener("message", (event) => {
      if (event.data && event.data.viewerAction){
        if (event.data.viewerAction == "artClick")
          this.selectViewerArt(event.data.artId, event.data.frameId)
        if (event.data.viewerAction == "artPlace")
          this.selectArtPosition(event.data.artId, event.data.frameId)
      }
    })

    this.moveToCurrentArt()

  },
  methods: {
    selectViewerArt(artId){
      this.$emit('selectArt', artId)
      this.skipMove =artId 
      
    },
    async selectArtPosition(artId, frameId){
      let art = this.documents.find( x => x.id == artId)

      if (!art){
        art = this.nfts.find( x => x.id == artId)
      }

      if (art){
        art.frameId = frameId
         const newDoc = await PA.Core.Proof.updateDocument({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          tokenId: this.value.tokenId,
          documentId: artId,
          data: art
        })
        this.skipMove =artId 
        this.$emit('updateArt', newDoc)
      }
    },
    moveToCurrentArt() {
      if (this.$refs.iframe){
        if (this.currentArt && (!this.skipMove || this.skipMove != this.currentArt.id)){
          this.$refs.iframe.contentWindow.postMessage({galleryAction: "moveToPicture", picture: this.currentArt.id, art: this.currentArt}, "*")
        }
      }
    },
    reload(){
        this.$refs.iframe.contentWindow.postMessage({galleryAction: "reload"}, "*")
    },
    openEditor(){
      this.popupMenu = false;
      this.$emit('selectTab', 0)
     },
     // remove the art from it's positon in the gallery
     async removeArt() {
        this.$refs.iframe.contentWindow.postMessage({galleryAction: "removeArt", picture: this.currentArt.id, frameId: this.currentArt.frameId}, "*")
        this.currentArt.frameId = null
        await PA.Core.Proof.updateDocument({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          tokenId: this.value.tokenId,
          documentId: this.currentArt.id,
          data: this.currentArt
        })
        this.skipMove =this.currentArt.id 
        this.popupMenu = false;

        this.$emit('updateArt', this.currentArt)
     },
     async saveArt(){
        await PA.Core.Proof.updateDocument({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          tokenId: this.value.tokenId,
          documentId: this.currentArt.id,
          data: this.currentArt
        })
        this.skipMove =this.currentArt.id 
        

        this.$refs.iframe.contentWindow.postMessage({galleryAction: "updateArtProperties", picture: this.currentArt.id, data: this.currentArt}, "*")
       
     }
  }
}
</script>

<template>
    <div>

    <v-dialog
      v-model="customizeDlg"
      width="800"
    >
      <v-card >
        <v-card-title class="text-h5 grey lighten-2 black--text">
          Customize your texture
        </v-card-title>
        
        <v-card-text class="mt-3" v-if="texture">
          <v-row>
            <v-col cols=5 class="pa-4">
              <h3>Base Color</h3>
               <v-color-picker
                dot-size="25"
                mode="rgba"
                class="no-alpha" flat hide-mode-switch
                v-model="texture.color"
                swatches-max-height="200"
                show-swatches
                @update:color="updateCustomization"
              ></v-color-picker>  
            </v-col>

            <v-col cols=7>
              <v-flex>
                <v-slider label="Repeat Scale" min=0.1 max=4 step=0.1  @change="updateCustomization" v-model="texture.repeat" hint="Customize how the texture repeats" persistent-hint thumb-label="always"></v-slider>
              </v-flex>
              <v-flex>
                <v-slider label="Normal Scale" min=0.0 max=1 step=0.1  @change="updateCustomization" v-model="texture.normalScale" hint="Customize how the light reflects off the texture" persistent-hint thumb-label="always"></v-slider>
              </v-flex>
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="customizeDlg = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="textureDlg"
      width="800"
    >
      <v-card >
        <v-card-title class="text-h5 grey lighten-2 black--text">
          Pick a Texture
        </v-card-title>
        
        <v-card-text class="mt-3">
            <div v-if="textures && textures.length > 0">
                <h3>Currently Used Textures</h3>
                <v-row>
                    <v-col cols=3 v-for="texture in textures" :key="texture.id" @click="selectExistingTexture(texture)">
                        <v-img :src="`${textureUrl}/${texture.id}/${texture.preview}`"></v-img>

                    </v-col>
                </v-row>
                <v-divider></v-divider>
                </div>

            <h3>All Textures</h3>
            <v-row>
                <v-col cols=3 v-for="texture in textureList" :key="texture.id" @click="selectTexture(texture)">
                    <v-img :src="`${textureUrl}/${texture.id}/${texture.preview}`"></v-img>

                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="textureDlg = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="texturePicker" >
      <v-row>
        <v-col cols=9>
          <div>{{label}}<span v-if="texture && texture.id">: {{textureName}} </span> </div>
          <v-btn small @click="textureDlg = true">Change</v-btn>
          <v-btn v-if="texture && texture.id" small @click="openCustomize()">Customize</v-btn>
        </v-col>
        <v-col cols=3>
          <v-img v-if="texture && texture.id" class="float-right" width=50 :src="texturePreview"></v-img>
          <v-btn class="mt-3 mx-3 float-right" v-if="texture && texture.id && texture.color&& texture.color != '#FFFFFFFF'" small fab :color="texture.color"></v-btn>
        </v-col>
        </v-row>
    </div>
</div>
</template>

<script>

export default {
  name: 'TexturePicker',
  props: {
      value: Object,
      label: String,
      textures: Array
  },

  data: function () {
    return {
        texture: {},
        textureDlg: false,
        customizeDlg: false,
         textureUrl: "https://beta.proveanything.com/gallery/textures",
    //     textureUrl: "http://127.0.0.1:8081/textures",
      textureList:
        [
            {id: "Arc_Pavement_001", name: "Arc Pavement", use:["floor"], maps: ["ao", "height", "normal", "rough"], preview:"Material_1118.jpg"},
            {id: "Brick_Wall_017", name: "Brick Wall 17", use:["wall"], maps: ["ao", "height", "normal", "rough"], preview:"Material_1360.jpg"},
            {id: "Ceiling_Gypsum_001", name: "Gypsum Ceiling", use:["ceiling"], maps: ["ao", "height", "normal", "rough", "emissive"], preview:"Material_Ceiling_011.png"},
            {id: "Cobblestone_001", name: "Cobblestone 1", use:["floor"], maps: ["ao", "height", "normal", "rough"], preview:"material_418.png"},
            {id: "Concrete_Blocks_011", name: "Concrete Blocks 11", use:["wall"], maps: ["ao", "height", "normal", "rough"], preview:"Material.jpg"},
            {id: "Metal_Copper_Roof_001", name: "Metal Copper", use:["wall", "ceiling"], maps: ["ao", "height", "normal", "metalic", "rough"], preview:"Material.jpg"},
            {id: "Stone_Tiles_003", name: "Stone Tiles 3", use:["floor"], maps: ["ao", "height", "normal", "rough"], preview:"material_493.png"},
            {id: "Stone_Tiles_004", name: "Stone Tiles 4", use:["floor", "wall"], maps: ["ao", "height", "normal", "rough"], preview:"Material_1473.jpg"},
            {id: "Tiles_048", name: "White and Gold Tiles", use:["floor", "wall"], maps: ["ao", "height", "normal", "metalic", "rough"], preview:"Material_1967.jpg"},
            {id: "Wallpaper_Woodchip_001", name: "WoodChip", use:["ceiling", "wall"], maps: ["ao", "height", "normal", "rough"], preview:"Material_1436.jpg"},
            {id: "Watercolor_Paper_001", name: "Watercolor paper", use:["ceiling", "wall"], maps: ["ao", "height", "normal", "rough"], preview:"Material_552.png"},
            {id: "Wood_Ceiling_Coffers_001", name: "Ceiling Coffers", use:["ceiling"], maps: ["ao", "height", "normal", "metalic", "rough"], preview:"Material_1495.jpg"},
            {id: "Wood_planks_011", name: "Wood Planks", use:["floor"], maps: ["ao", "height", "normal", "rough"], preview:"Material_962.jpg"},
            {id: "Wood_Floor_011", name: "Wood Floor 11", use:["floor"], maps: ["ao","normal", "height", "rough"], preview:"Material_1902.jpg"},
            {id: "Wood_Floor_007", name: "Wood Floor 7", use:["floor"], maps: ["ao","normal","rough"], preview:"material_382.png"},
            {id: "Wood_Floor_006", name: "Wood Floor 6", use:["floor"], maps: ["ao","normal","rough"], preview:"material_380.png"},
            {id: "Wood_Floor_008", name: "Wood Floor 8", use:["floor"], maps: ["ao","normal","rough"], preview:"material_383.png"},
            {id: "Grainy_Wood_001", name: "Grainy Wood 1", use:["floor"], maps: ["ao","normal","rough"], preview:"material_351.png"},
            {id: "Wood_planks_012", name: "Wood Planks 12", use:["floor", "wall", "ceiling"], maps: ["ao","normal","rough"], preview:"Material_1025.jpg"},
            {id: "Wood_Herringbone_Tiles_002", name: "Wood Herringbone 2", use:["floor", "wall", "ceiling"], maps: ["ao","normal","rough"], preview:"Material_1647.jpg"},
            {id: "Wood_Tiles_003", name: "Wood Tiles 3", use:["floor", "wall", "ceiling"], maps: ["ao","normal","rough"], preview:"Material_1699.jpg"},
            {id: "Wood_Herringbone_Tiles_003", name: "Wood Herringbone 3", use:["floor", "wall", "ceiling"], maps: ["ao","normal","rough"], preview:"Material_1715.jpg"},
            {id: "Tiles_Terracotta_005", name: "Tiles Terracotta 5", use:["floor"], maps: ["ao","height","normal","rough"], preview:"Material_1734.jpg"},
            {id: "Bamboo_Weave_001", name: "Bamboo Weave 1", use:["floor", "wall"], maps: ["ao","height","normal","rough"], preview:"Material_1836.jpg"},
            {id: "Wood_Floor_010", name: "Wood Floor 10", use:["floor"], maps: ["ao","height","normal","rough"], preview:"Material_1887.jpg"},
            {id: "Wood_Ceiling_001", name: "Wood Ceilig 1", use:["ceiling"], maps: ["ao","height","normal","rough"], preview:"Material_1855.jpg"},
            {id: "Wood_Floor_012", name: "Wood Floor 12", use:["floor"], maps: ["ao","height","normal","rough"], preview:"Material_1982.jpg"},
            {id: "Wood_Particle_Board_003", name: "Wood Partcile Board 3", use:["floor", "wall", "ceiling"], maps: ["ao","height","normal","rough"], preview:"Material_1901.jpg"},
       ]
     
    }
  },  
  computed: {
    user(){
      return this.$store.state.user
    },
    textureName(){
      const texture = this.textureList.find( t => t.id == this.texture.id)
      console.log("--", this.texture, texture);
      if (texture)
        return texture.name
      else
        return "-Not found-"
    },
    texturePreview(){
      const texture = this.textureList.find( t => t.id == this.texture.id)
      if (texture)
        return `${this.textureUrl}/${texture.id}/${texture.preview}`
      else
        return null
    }
  },
  mounted() {
      console.log(this.value)
      if (this.value)
        this.texture = Object.assign({}, this.value)
      else
        this.texture = {color:"#FFFFFF"}
  },
  watch: {
      value: function() {
          if (this.value)
            this.texture = Object.assign({}, this.value)
          else
            this.texture = {color:"#FFFFFF"}
      }
  },
  methods : {
      selectTexture(texture){

        if (!this.textures.find( t => t.id == texture.id)){
          const textureArr = [...this.textures]
          textureArr.push(texture)
          this.$emit('updateTextures',textureArr)
        }
        this.selectExistingTexture(texture) 

      },
      selectExistingTexture(texture){

        console.log("select existing", texture, this.texture, this.texture.color)

        this.texture.name = texture.name
        this.texture.id = texture.id
        

        this.textureDlg = false;
        this.$emit('input', this.texture)

      },
      updateCustomization(){
        console.log("update custom", this.texture)
        this.$emit('input', this.texture)
      },
      openCustomize(){
        this.customizeDlg = true
        this.texture.color = this.texture.color  || "#FFFFFFFF";
        this.texture.repeat = this.texture.repeat || 1;
        this.texture.normalScale = this.texture.normalScale || 1;
      }

   
  }
}
</script>

<style lang="scss">


.texturePicker {
  margin:8px;
  padding:8px;
  border: 1px solid #666;
  border-radius: 8px;
  min-height: 85px
}
</style>
<template>
  <v-col cols=12>
    <p>Gallery Setup : Advanced Config</p>

    <v-container>
      <v-btn class="primary float-right" @click="save()">Save</v-btn>
      <v-row>
        <v-col cols=3>
          <v-list shaped dense>
            <v-subheader>Rooms</v-subheader>
            <v-list-item-group v-model="selectedRoom" color="primary">
              <v-list-item v-for="(item, i) in settings.rooms" :key="i" @click="selectRoom(item)">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="addRoom()">
                <v-list-item-content>
                  <v-list-item-title>- Add new -</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols=9>
          <div v-if="currentRoom">
            <h3>Ceiling</h3>
            <v-flex>
              <v-text-field v-model="currentRoom.name" label="Name"></v-text-field>
            </v-flex>
            <v-flex class="my-4">
              <v-slider label="Height" min=5 max=30 v-model="currentRoom.height" hint="" persistent-hint
                thumb-label="always"></v-slider>
            </v-flex>
            <v-flex class="my-4">
              <v-slider label="Width" min=5 max=100 step=5 v-model="currentRoom.width" hint="" persistent-hint
                thumb-label="always"></v-slider>
            </v-flex>
            <v-flex class="my-4">
              <v-slider label="Depth" min=5 max=100 step=5 v-model="currentRoom.depth" hint="" persistent-hint
                thumb-label="always"></v-slider>
            </v-flex>
            <v-flex>
              <v-select v-model="currentRoom.roof.type" :items="['flat', 'apex']" label="Ceiling Type"></v-select>
            </v-flex>
            <v-flex>
              <v-select v-model="currentRoom.roof.roofLights" :items="['none', '4', '8', '12']" label="Roof Lights">
              </v-select>
            </v-flex>
            <v-flex>
              <TexturePicker v-model="currentRoom.roof.texture" :textures="settings.textures"
                @updateTextures="updateTextures" label="Ceiling Texture"></TexturePicker>
            </v-flex>
            <v-divider class="my-2"></v-divider>
            <h4>Walls</h4>
            <v-flex>
              <TexturePicker v-model="currentRoom.walls.texture" :textures="settings.textures"
                @updateTextures="updateTextures" label="Wall Texture"></TexturePicker>
            </v-flex>
            <v-flex>
              <v-switch v-model="currentRoom.walls.skirting" label="Skirting board"></v-switch>
            </v-flex>
            <v-flex>
              <v-switch v-model="currentRoom.walls.moulding" label="Moulding"></v-switch>
            </v-flex>
            <v-flex>
              <v-switch v-model="currentRoom.walls.lights" label="Lights"></v-switch>
            </v-flex>
            <v-divider class="my-2"></v-divider>
            <h4>Floor</h4>
            <v-flex>
              <TexturePicker v-model="currentRoom.floor.texture" :textures="settings.textures"
                @updateTextures="updateTextures" label="Floor Texture"></TexturePicker>
            </v-flex>



          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import TexturePicker from "../TexturePicker.vue"
import Vue from 'vue'

export default {
  name: "RoomEditor",
  props: {
    value: Object,
  },
  watch: {
    value() {
      this.loadSettings();
    }
  },
  data: function () {
    return {
      settings: {},
      selectedRoom: null,
      currentRoom: null,
      editTextures: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.loadSettings()
  },
  methods: {
    loadSettings() {
      // clone vue wrapping to json
      if (this.value.meta)
        this.settings = JSON.parse(JSON.stringify(this.value.meta.gallerySetup || {}));
      else
        this.settings = {}

      this.settings = this.settings || {}
      this.settings.rooms = this.settings.rooms || []
      Vue.set(this.settings, 'textures', this.settings.textures || [])

      this.settings.textures = this.settings.textures.filter(x => typeof x.maps !== "undefined")

      this.selectRoom(this.settings.rooms[0])
    },
    selectRoom(room) {
      this.currentRoom = room;
      this.currentRoom = this.currentRoom || {}
      this.currentRoom.roof = this.currentRoom.roof || { type: "flat", roofLights: "4" }
      this.currentRoom.walls = this.currentRoom.walls || { skirting: true, moulding: true }
      this.currentRoom.floor = this.currentRoom.floor || {}
      this.currentRoom.width = this.currentRoom.width || 20
      this.currentRoom.depth = this.currentRoom.depth || 20
    },
    addRoom() {
      this.currentRoom = {
        name: `Room ${this.settings.rooms.length + 1}`,
        roof: { type: "flat", roofLights: "4" },
        walls: { skirting: true, moulding: true },
        floor: {},
        height: 6,
        width: 20,
        depth: 20

      };
      this.settings.rooms.push(this.currentRoom)
    },
    updateTextures(textures) {
      this.settings.textures = textures
    },
    save() {
      //        this.$emit('input', this.settings)
      this.$emit("save", this.settings)
    }
  },
  components: { TexturePicker }
}
</script>

<style lang="scss">
</style>
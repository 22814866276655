<template>
  <v-container>
    <v-row class="text-center">
      
      <v-col cols="12">

      </v-col>

      <v-col class="mb-4">


        <LoginDialog @success="loginSuccess()"></LoginDialog>

      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import LoginDialog from "./LoginDialog"

  export default {
    name: 'Login',
    components: {LoginDialog},


    data: () => ({
    
    }),
    methods: {
      loginSuccess(){
        this.loginDlg = false
      },
    }
  }
</script>

<template>
  <v-container>

    <v-dialog v-model="editDlg" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="isUserEdit">Edit {{ currentUser.name }}</v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>Create New User</v-card-title>
        <v-card-text>
          <!--                    <v-flex>
                        <v-text-field class="mx-5 mt-5" label="Name" :disabled="isUserEdit" v-model="currentUser.name"></v-text-field>
                    </v-flex>
                    <v-flex>
                        <v-text-field class="mx-5" label="Email"  :disabled="isUserEdit"  v-model="currentUser.email"></v-text-field>
                    </v-flex>
                    <v-flex class="text-center">
                       - or - 
                    </v-flex>
                    -->
          <v-flex>
            <v-text-field class="mx-5" label="Public Wallet" :disabled="isUserEdit" v-model="currentUser.publicWallet">
            </v-text-field>
          </v-flex>
          <v-flex>
            <v-select class="mx-5" v-model="currentUser.access" label="Access Level" :items="accessTypes"></v-select>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editDlg = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="removeUser()" class="error" v-if="isUserEdit">
            <v-icon>mdi-delete</v-icon>Remove User
          </v-btn>
          <v-btn @click="saveUser()" class="primary">
            <v-icon>mdi-content-save</v-icon> Save User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn class="primary float-right" @click="openAddNew()">
        <v-icon>mdi-account</v-icon> Add New User
      </v-btn>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              User Name
            </th>
            <th class="text-left">
              User ID
            </th>
            <th class="text-left">
              User Access
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan=4 v-if="loading">
              <v-progress-linear indeterminate />
            </td>
          </tr>
          <tr v-for="(role, id, index) in users" :key="index">
            <td>-</td>
            <td>{{ id }}</td>
            <td>{{ role }}</td>
            <td>
              <v-btn @click="openEdit(id)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-container>
</template>

<script>
import Vue from 'vue'

export default {
  name: "EditSiteMain",
  props: {
    value: Object
  },
  components: {
  },
  computed: {
  },
  data: () => ({
    microsite: null,
    users: {},
    loading: false,
    currentUser: {},
    editDlg: false,
    accessTypes: ["Editor"],
    isUserEdit: false,
  }),
  async mounted() {
    await this.loadUsers()
  },
  methods: {
    async loadUsers() {
      this.loading = true

      this.users = this.value.roles || {}

      // later we could do this as a proper heirachy!

      this.loading = false
    },
    openAddNew() {
      this.editDlg = true;
      this.isUserEdit = false;
      this.currentUser = {};
    },
    openEdit(id) {
      this.editDlg = true;
      this.isUserEdit = true
      this.currentUser = {publicWallet: id, access: this.users[this.currentUser.publicWallet]};
    },
    async removeUser() {
      this.editDlg = false;
      Vue.delete(this.users, this.currentUser.publicWallet);
      this.$emit("save", this.users)
    },
    async saveUser() {
      this.editDlg = false;

      Vue.set(this.users, this.currentUser.publicWallet, this.currentUser.access)

      this.$emit("save", this.users)
    }

  }
};
</script>

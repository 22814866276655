<template>
  <v-main>
   
  <v-navigation-drawer app v-if="user">

      <v-list class="slideimage">

        <v-img alt="Galleryland Logo" src="/logo.png" transition="scale-transition" :to="{ name: 'galleries' }" />

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-center" v-if="user">
              {{ user.displayName || user.uid }}
            </v-list-item-title>

            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list v-if="sideView == 0" nav dense>
        <v-list-item-content>
          <v-list-item-title class="text-center">
            Gallery Worlds

          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-group v-model="selectedWorld" color="primary">
          <v-list-item v-for="(world, i) in worlds" :key="i" @click="selectWorld(world)">
            <v-list-item-icon>
              <img :src="world.holdImage">
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ world.name }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>
    <div>
      <ListGalleries v-if="currentWorld" :proofGroup="currentWorld.proofGroup"/>
    </div>
  </v-main>
</template>

<script>
import ListGalleries from '@/components/ListGalleries';
import PA from '@proveanything/base'

export default {
  name: 'Auth',

  components: {
    ListGalleries,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isDark() {
      return this.$vuetify.theme.dark
    },
  },
  data: () => ({
    proofGroup: null,
    worlds: [],
    selectedWorld: 0,
    currentWorld: null,
    sideView: 0,

  }),
  async mounted() {

    const galleries = [
      {collection: "GalleryLand", proofGroup: "zQZiZDMlVRYLD36xqHrG", name: "Tokenists" },
      {collection: "art", proofGroup: "bcac-test", name: "Legacy Tokenists" },
      {collection: "GalleryLand", proofGroup: "Gd4vjqGxeGEzUc8hSant", name: "Test Tokenists" },
    ]

    for (const gallery of galleries){
      let proofGroup = await PA.Core.ProofGroup.get(gallery.collection, gallery.proofGroup)
      
      if (proofGroup.holdImage)
          this.holdImage = proofGroup.holdImage.url

      this.worlds.push({
        name: gallery.name,
        holdImage: this.holdImage,
        proofGroup: proofGroup
      })
      
    }
    this.currentWorld = this.worlds[0]

  },
  methods: {
    selectWorld(world) {
      this.currentWorld = world
    },
  }
};
</script>

<style >

</style>
import Vue from 'vue'
import Vuex from 'vuex'

import PA from '@proveanything/base'
import '@proveanything/auth'
import '@proveanything/ethereum'


// config the PA link
PA.setup({host: "https://beta.proveanything.com"})
//PA.setup({host: "http://127.0.0.1:8080"})

Vue.use(Vuex)

const store =  new Vuex.Store({
  state: {
    user: null,
    loginCount: 0
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    incLoginCount(state){
      state.loginCount +=1;
    }
  },
  actions: {
  },
  modules: {
  }
})


PA.Auth.User.onUserChange( (user) => {
  store.commit('user', user)
  store.commit('incLoginCount')
});

export default store
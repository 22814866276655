<template>
  <v-row class="pa-3">

    <v-col cols=12>
      <div v-if="!currentArt || showUpload">

        <v-file-input v-model="file" label="Upload Art"></v-file-input>
        <v-btn color="primary" v-if="file && !indeterminateProgress" @click="addManualFile()">Upload Image</v-btn>
        <v-progress-linear v-if="file && file.progress" color="blue accent-4" :value="file.progress" rounded height="6">
        </v-progress-linear>
        or
        <v-flex cols=12>
          <v-text-field label="Copy from link" v-model="uploadUrl" @click:append-outer="copyFromLink()"
            append-outer-icon="mdi-upload"></v-text-field>
        </v-flex>
        <v-progress-linear v-if="indeterminateProgress" color="blue accent-4" indeterminate rounded height="6">
        </v-progress-linear>
      </div>
      <div v-if="currentArt && !showUpload">
        <div v-if="currentArt.nft">
          <v-row class="pa-4">
            <v-btn class="teal darken-1  mx-2 white--text" @click="openSea()">View on Opensea</v-btn>
            <v-spacer></v-spacer>
            <v-img max-width="50" src="/nft.png"></v-img>
          </v-row>

          <v-flex cols=12>
            <v-text-field label="Name" readonly v-model="currentArt.meta.name"></v-text-field>
          </v-flex>

          <v-flex cols=12>
            <v-text-field label="Description" readonly v-model="currentArt.meta.description"></v-text-field>
          </v-flex>

          <v-flex cols=12 v-if="currentArt.meta.properties">
            <h5>Attributes</h5>
            <v-card v-for="(prop, idx) in currentArt.meta.properties" :key="idx" class="ma-2 float-left" width="220"
              outlined color="green" dark>
              <v-list-item two-line class="px-2">
                <v-list-item-content class="py-0">
                  <div class="text-overline mb-0">
                    {{ prop.trait_type }}
                  </div>
                  <v-list-item-title class="text mb-0">
                    {{ prop.value }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <br clear="all"/>
          </v-flex>

          <v-flex cols=12>
            <v-text-field label="Custom View Link" v-model="currentArt.viewLink"></v-text-field>
          </v-flex>
        </div>
        <div v-else>
          <v-row class="pa-4">
            <v-btn class="teal darken-1  mx-2 white--text" @click="showUpload = true">Change Art</v-btn>
            <v-btn class="warning darken-1 mx-2" @click="deleteArt()">Delete</v-btn>
            <v-btn class="primary darken-3 mx-2" @click="saveArt()">Save</v-btn>
            <v-spacer></v-spacer>
            <v-img v-if="currentArt.thumbnails" max-width=100 :src="currentArt.thumbnails.x100"></v-img>
          </v-row>
          <v-divider></v-divider>
          <v-flex cols=12>
            <v-text-field label="Name" v-model="currentArt.name"></v-text-field>
          </v-flex>
          <v-flex cols=12>
            <v-text-field label="Artist" v-model="currentArt.artist"></v-text-field>
          </v-flex>
          <v-flex cols=12>
            <v-textarea label="Description" v-model="currentArt.description"></v-textarea>
          </v-flex>
          <v-flex cols=12>
            <v-text-field label="Purchase Link" v-model="currentArt.purchaseLink"></v-text-field>
          </v-flex>
          <v-flex cols=12>
            <v-text-field label="View Link" v-model="currentArt.viewLink"></v-text-field>
          </v-flex>
        </div>

        <v-flex cols=12>
          <v-switch label="Frame" v-model="currentArt.frame"></v-switch>
        </v-flex>
        <v-flex cols=12>
          <v-slider label="Size" min=10 max=200 v-model="currentArt.size" hint="Size % from default" persistent-hint
            thumb-label="always"></v-slider>
        </v-flex>
        <v-flex cols=12>
          <v-slider label="Vertical Offset" min=-3 max=10 step=0.1 v-model="currentArt.heightOffset"
            hint="Offset from default height" persistent-hint thumb-label="always"></v-slider>
        </v-flex>
        <v-flex cols=12>
          <v-btn class="primary darken-3 mt-4" @click="saveArt()">Save</v-btn>
        </v-flex>

        <img v-if="currentArt.thumbnails" style="display:none" :src="currentArt.thumbnails.x512" ref="img512">
      </div>
    </v-col>
  </v-row>
</template>

<script>

import PA from '@proveanything/base'
//import EventBus from '@/components/EventBus'

export default {
  name: "CharacterView",
  components: {
  },
  props: {
    proofGroup: Object,
    value: Object,
    currentArt: Object,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isDark() {
      return this.$vuetify.theme.dark
    },
  },
  watch: {
  },
  data: () => ({
    account: null,
    sideView: 0,
    file: null,
    showUpload: false,
    currentArtId: null,
    uploadUrl: null,
    indeterminateProgress: false,
    gallerySetup: "",
    tab: "0",
    holdImage: null
  }),
  async mounted() {

    //EventBus.$on('uploadProgress', async () => {
    //  this.$forceUpdate()
    //});    

  },
  methods: {
    async openSea() {
      console.log(this.currentArt)
      const url = `https://opensea.io/assets/${this.currentArt.chain}/${this.currentArt.nft.token_address}/${this.currentArt.nft.token_id}`
      window.open(url, "_blank")
    },
    async addManualFile() {
      this.indeterminateProgress = true

      const result = await PA.Core.Personal.uploadImage(this.file)

      this.value.meta = this.value.meta || {}

      //  const meta = this.value.meta

      //  meta.art = meta.art || []

      let split = result.url.split("/")
      let mini = split.slice(0, -1).join("/") + "/thumbnails/" + split.at(-1).split(".").slice(0, -1).join(".")
      const ext = split.at(-1).split(".").at(-1)
      let mini100 = `${mini}_100x100.${ext}`
      let mini200 = `${mini}_200x200.${ext}`
      let mini512 = `${mini}_512x512.${ext}`
      let newArt = null

      if (this.currentArt) {
        // update the image for the current art
        this.currentArt.url = result.url
        this.currentArt.thumbnails = {
          x100: mini100,
          x200: mini200,
          x512: mini512
        }

      } else {
        // add new art
        newArt = {
          size: 100,
          heightOffset: 0,
          frame: true,
          name: result.name,
          url: result.url,
          thumbnails: {
            x100: mini100,
            x200: mini200,
            x512: mini512
          }
        }
        this.$emit("updateArt", newArt)
        // this.currentArt = newArt
        // meta.art.push(newArt)

      }

      setTimeout(() => {
        if (newArt)
          this.$emit("updateArt", newArt)
        //        this.currentArt = newArt
        this.uploadUrl = null
        this.file = null
        this.showUpload = false;
        this.indeterminateProgress = false
        setTimeout(async () => {
          await this.saveArt();
        }, 2000);

      }, 4000);


    },
    async copyFromLink() {

      if (!this.uploadUrl || this.uploadUrl.length == 0)
        return


      this.indeterminateProgress = true

      const result = await PA.Core.Personal.uploadFromLink({
        url: this.uploadUrl,
        addToLibrary: true
      }
      )


      //   this.value.meta = this.value.meta || {}

      //  const meta = this.value.meta

      //   meta.art = meta.art || []

      let split = result.url.split("/")
      let mini = split.slice(0, -1).join("/") + "/thumbnails/" + split.at(-1).split(".").slice(0, -1).join(".")
      const ext = split.at(-1).split(".").at(-1)
      let mini100 = `${mini}_100x100.${ext}`
      let mini200 = `${mini}_200x200.${ext}`
      let mini512 = `${mini}_512x512.${ext}`
      let newArt = null

      if (this.currentArt) {
        // update the image for the current art
        this.currentArt.url = result.url
        this.currentArt.thumbnails = {
          x100: mini100,
          x200: mini200,
          x512: mini512
        }

      } else {
        // add new art
        newArt = {
          size: 100,
          heightOffset: 0,
          frame: true,
          name: result.name,
          url: result.url,
          thumbnails: {
            x100: mini100,
            x200: mini200,
            x512: mini512
          }
        }
        this.$emit("updateArt", newArt)
        //  this.currentArt = newArt
        //        meta.art.push(newArt)
      }

      setTimeout(() => {
        if (newArt)
          this.$emit("updateArt", newArt)
        //        this.currentArt = newArt
        this.uploadUrl = null
        this.file = null
        this.showUpload = false;
        this.indeterminateProgress = false
        setTimeout(async () => {
          await this.saveArt();
        }, 2000);

      }, 4000);

    },

    async saveArt() {

      if (this.$refs.img512 && this.currentArt) {
        console.log(this.$refs.img512.width, this.$refs.img512.height)
        this.currentArt.height512 = this.$refs.img512.height
        this.currentArt.width512 = this.$refs.img512.width
      }

      if (!this.currentArt.id) {
        // new document
        const newDoc = await PA.Core.Proof.addDocument({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          data: this.currentArt,
          tokenId: this.value.tokenId
        })
        //        this.currentArt = newDoc
        console.log(newDoc)
        this.$emit("addArt", newDoc)
      } else {
        // update existing document
        const newDoc = await PA.Core.Proof.updateDocument({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          tokenId: this.value.tokenId,
          documentId: this.currentArt.id,
          data: this.currentArt
        })
        //        this.currentArt = newDoc
        console.log(newDoc)
        this.$emit("updateArt", newDoc)
      }
    },
    selectItem(item) {
      this.currentArt = item;
      this.showUpload = false;

      if (typeof this.currentArt.frame === "undefined") {
        this.currentArt.frame = true
      }
      if (typeof this.currentArt.size === "undefined") {
        this.currentArt.size = 100
      }
      if (typeof this.currentArt.heightOffset === "undefined") {
        this.currentArt.heightOffset = 0
      }
    },
    async deleteArt() {

      await PA.Core.Proof.deleteDocument({
        collectionId: this.proofGroup.site,
        proofGroupId: this.proofGroup.id,
        tokenId: this.value.tokenId,
        documentId: this.currentArt.id
      })

      this.$emit("deleteArt", this.currentArt.id)

    },


    async saveAndClear() {
      await this.saveArt()
      this.currentArt = null;
    },


  }
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Galleries from "@/views/Galleries.vue"
import EditGallery from "@/views/EditGallery.vue"

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Home
  },
  {
    path: '/galleries',
    name: 'galleries',
    component: Galleries
  },
  {
    path: '/edit/:site/:proofGroup/:tokenId',
    name: 'editGallery',
    component: EditGallery
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

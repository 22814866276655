<template>
  <v-app id="app">
     <v-system-bar app color="#FAA471" dark class="topbar" v-if="user">
      <v-spacer></v-spacer>

      <v-btn @click="logout()" text>
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-system-bar>
    <router-view/>
  </v-app>
</template>

<script>

import PA from "@proveanything/base";


export default {
  name: "App",
  components: {
  },
  props: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    '$store.state.loginCount': function() {
      if (!this.user) {
        this.$router.push({name: "login"})
      }
    },
  },
  methods: {
      async logout(){
//        await this.$store.dispatch('logout')
        await PA.Auth.User.logout()
        this.$router.push({name: "login"})
      }
  }
}
</script>

<style lang="scss">
#xapp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-toolbar__content {
  padding-left:0px;
}

.topbar {
  background-color: #FAA471;
  background-image: url("/logo-line.png");
  background-repeat:repeat-y ;
}
</style>



const Moralis = require('moralis');

exports.getNFTs = async (chain, address) => {

  const options = { chain: chain, address: address };

  const nfts = await Moralis.Web3API.account.getNFTs(options)

  return nfts
}

exports.getMetadata = async (chain, nft) => {

  const options = { chain: chain, address: nft.token_address, token_id: nft.token_id };

  const meta = await Moralis.Web3API.token.getTokenIdMetadata(options)

  return meta
}
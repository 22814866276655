<template>
  <v-main>
    <div v-if="user && gallery">
      <v-navigation-drawer app width="300px">
        <v-list class="slideimage">
          <router-link :to="{ name: 'galleries' }">
            <v-img alt="Galleryland Logo" src="/logo.png" transition="scale-transition" />
          </router-link>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6 text-center" v-if="user">
                {{ user.displayName || user.uid }}
              </v-list-item-title>

              <v-list-item-subtitle>{{ account }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-tabs v-model="activeTab">
          <v-tabs-slider color="dark-blue"></v-tabs-slider>

          <v-tab>Custom Images</v-tab>
          <v-tab>NFTs</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-list shaped nav dense>
              <v-list-item @click="addNew()">
                <v-list-item-icon>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>- Add new -</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in documents" :key="i" @click="selectItem(item)">
                  <v-list-item-icon class="my-0 mr-2" style="height:40px">
                    <v-img v-if="item.thumbnails" max-width="60" :src="item.thumbnails.x100"></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0" v-if="item.frameId">
                    <v-btn icon x-small>
                      <v-icon color="grey lighten-1">mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>

          <v-tab-item>
            <v-list shaped nav dense>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in nfts" :key="i" @click="selectItem(item)">
                  <v-list-item-icon class="my-0 mr-2" style="height:40px">
                    <v-img v-if="item.image" max-width="60" :src="item.image"></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.meta.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0" v-if="item.frameId">
                    <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

             
              </v-list-item-group>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-navigation-drawer>

      <v-container class="py-8 px-6" fluid>
        <div v-if="gallery" class="characterPreview" :class="{ user: user, dark: isDark, light: !isDark }">
          <v-row class="pa-3">
            <v-avatar size="128">
              <img :src="holdImage" />
            </v-avatar>
            <v-col>
              <span>Tokenist: # {{ gallery.tokenId }}</span>
              <span class="float-right">
                <v-btn target="_blank"
                  :href="`https://viewer.galleryland.xyz/gallery/${proofGroup.site}/${proofGroup.id}/${gallery.tokenId}`">
                  <v-icon>mdi-open-in-new</v-icon> Open Gallery
                </v-btn>
              </span>
            </v-col>
          </v-row>
          <v-tabs class="mt-2" v-model="tab" align-with-title>
            <v-tabs-slider color="blue"></v-tabs-slider>

            <v-tab key="imageEditor"> Images </v-tab>
            <v-tab key="gallerySetup"> Gallery Setup </v-tab>
            <v-tab key="galleryLayout"> Gallery Layout </v-tab>
            <v-tab key="userAccess"> Access </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="imageEditor">
              <ImageEditor :proofGroup="proofGroup" v-model="gallery" :currentArt="currentArt" :tab="tab"
                @addArt="addArt" @updateArt="updateArt" @deleteArt="deleteArt"></ImageEditor>
            </v-tab-item>
            <v-tab-item key="gallerySetup">
              <RoomEditor v-model="gallery" :tab="tab" @save="saveGallerySetup"></RoomEditor>
            </v-tab-item>
            <v-tab-item key="galleryLayout">
              <LayoutEditor :proofGroup="proofGroup" v-model="gallery" :documents="documents" :nfts="nfts" :currentArt="currentArt"
                :tab="tab" @updateArt="updateArt" @selectArt="selectArt" @selectTab="selectTab"></LayoutEditor>
            </v-tab-item>
            <v-tab-item key="userAccess">
              <AccessEditor :proofGroup="proofGroup" v-model="gallery"  @save="saveGalleryRoles"></AccessEditor>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-container>
    </div>
  </v-main>
</template>

<script>
//import Api from '@/api'
import PA from "@proveanything/base";
//import EventBus from '@/components/EventBus'
import RoomEditor from "@/components/Editor/RoomEditor";
import ImageEditor from "@/components/Editor/ImageEditor";
import LayoutEditor from "@/components/Editor/LayoutEditor";
import AccessEditor from "@/components/Editor/AccessEditor";

import moralis from "@/lib/moralis";

export default {
  name: "CharacterView",
  components: {
    RoomEditor,
    ImageEditor,
    LayoutEditor,
    AccessEditor
  },
  props: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    user() { },
  },
  data: () => ({
    activeTab: 0,
    account: null,
    sideView: 0,
    showTaken: false,
    currentArt: null,
    file: null,
    currentArtId: null,
    tab: "imageEditor",
    holdImage: null,
    gallery: null,
    documents: [],
    nfts: [],
    hasNFT: false
  }),
  async mounted() {

    this.proofGroup = await PA.Core.ProofGroup.get(
      this.$route.params.site,
      this.$route.params.proofGroup
    );

    if (this.proofGroup.holdImage)
      this.holdImage = this.proofGroup.holdImage.url;

    this.gallery = await PA.Core.Proof.get(
      this.proofGroup.site,
      this.proofGroup.id,
      this.$route.params.tokenId
    );
    this.documents = await PA.Core.Proof.getDocuments(
      this.proofGroup.site,
      this.proofGroup.id,
      this.$route.params.tokenId
    );
    this.nfts = this.documents.filter( doc => doc.nft)
    this.documents = this.documents.filter( doc => !doc.nft)

  
    this.getNFTs();
    //EventBus.$on('uploadProgress', async () => {
    //  this.$forceUpdate()
    //});
  },
  methods: {
    async getNFTs() {

      this.hasNFT = this.user.uid.includes("0x")

      if (!this.hasNFT)
        return

      const usednfts = {}
      this.nfts.forEach( nft => usednfts[nft.id] = nft)

      const chainList = ["eth", "matic"]

      for (const chain of chainList){

        console.log("chain", chain)

        const nfts = await moralis.getNFTs(
          chain,
          this.user.uid
        );

        nfts.result.forEach(async (nft) => {
          const id = `${nft.token_address}-${nft.token_id}`
          if (nft.metadata && !usednfts[id]) {
            const item = {
              id: id,
              nft: nft,
              meta: JSON.parse(nft.metadata),
              chain: (chain == "eth" ? "ethereum" : chain),
            };

            item.image = item.meta.image || item.meta.image_url
            if (item.image) {
              if (item.image.includes("ipfs:"))
                item.image = "https://ipfs.io/ipfs/" + item.image.substr(7);
            }

            this.nfts.push(item);
          }
          //          const meta = await moralis.getMetadata("Eth", nft)
        });
      }
    },

    selectItem(item) {
      this.currentArt = item;

      if (typeof this.currentArt.frame === "undefined") {
        this.currentArt.frame = true;
      }
      if (typeof this.currentArt.size === "undefined") {
        this.currentArt.size = 100;
      }
      if (typeof this.currentArt.heightOffset === "undefined") {
        this.currentArt.heightOffset = 0;
      }
    },
    selectTab(tab) {
      this.tab = tab;
    },
    addArt(doc) {
      this.documents.push(doc);
      this.currentArt = doc;
    },
    updateArt(doc) {
      this.documents[this.documents.findIndex((x) => x.id == doc.id)] = doc;
      this.currentArt = doc;
    },
    deleteArt(docId) {
      const idx = this.documents.findIndex((x) => x.id == docId);
      this.documents.splice(idx, 1);
      this.currentArt = this.documents[idx];
    },
    selectArt(docId) {
      this.currentArt = this.documents.find((x) => x.id == docId) ||  this.nfts.find((x) => x.id == docId)
    },
    async addNew() {
      this.currentArt = null;
      this.tab = 0;
    },
    async saveGalleryRoles(roles) {
      try {
        this.gallery.roles = roles;

        await PA.Core.Proof.updateRoles({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          data: roles,
          tokenId: this.gallery.tokenId,
        });
      } catch (err) {
        this.error = err;
        console.error(err);
      }
    },
    async saveGallerySetup(gallerySetup) {
      try {
        this.gallery.meta = this.gallery.meta || {};
        this.gallery.meta.gallerySetup = gallerySetup;

        await PA.Core.Proof.updateMeta({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
          data: this.gallery.meta,
          tokenId: this.gallery.tokenId,
        });
      } catch (err) {
        this.error = err;
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
/*
.v-navigation-drawer__content::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
.v-navigation-drawer__content::-webkit-scrollbar{
  width: 10px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
  border-radius: 5px;
}
*/

.theme--light .theme--light.v-list.slideimage {
  background-color: #faa471;
  background-image: url("/logo-line.png");
  background-repeat: repeat-y;
  border-top: #d8763e 1px solid;
}

.characterPreviewx {
  overflow-y: scroll;
  position: fixed;
  bottom: 50px;
  top: 140px;
  left: 20px;
  right: 20px;
  border: 10px solid #666;
  border-radius: 6px;

  &.user {
    left: 300px;
  }
}
</style>
<template>
  <div>

  
    <v-container class="py-8 px-6" fluid>
      <v-toolbar flat>
        <v-toolbar-title> {{proofGroup.name}}: Your Galleries</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="float-right ma-2" x-small fab @click="getUsersTokens(true)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card v-for="(item, i) in yourTokens" max-width="100" class="ma-3" style="float:left" :key="i"
        @click="selectGallery(item)">
        <v-card-title># {{ item.nftId }}</v-card-title>
        <v-card-text>
          <v-img v-if="proofGroup.holdImage" :src="proofGroup.holdImage.url"></v-img>
        </v-card-text>
      </v-card>
      <br clear="all"/>
    </v-container>
      <v-divider></v-divider>
    <v-container class="py-8 px-6" fluid>

      <v-toolbar flat>
        <v-toolbar-title> {{proofGroup.name}}:  Galleries Shared With you</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="float-right ma-2" x-small fab @click="getSharedTokens(true)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card v-for="(item, i) in sharedTokens" max-width="100" class="ma-3" style="float:left" :key="i"
        @click="selectGallery(item)">
        <v-card-title># {{ item.nftId }}</v-card-title>
        <v-card-text>
          <v-img :src="holdImage"></v-img>
        </v-card-text>
      </v-card>

    </v-container>

  </div>
</template>

<script>
//import Api from '@/api'
import PA from '@proveanything/base'
//import EventBus from '@/components/EventBus'

export default {
  name: "ListGalleries",
  components: {
  },
  props: {
    proofGroup: Object,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isDark() {
      return this.$vuetify.theme.dark
    },
  },
  watch: {
    user() {
      this.getUsersTokens();
      this.getSharedTokens();
    },
    proofGroup() {
      this.getUsersTokens();
      this.getSharedTokens();
    }
  },
  data: () => ({
    yourTokens: [],
    sharedTokens: [],
    collagePath: null,
    showTaken: false,
    holdImage: null,
  }),
  async mounted() {

 

    if (this.user) {
      this.getUsersTokens()
      this.getSharedTokens();

    }

    //EventBus.$on('uploadProgress', async () => {
    //  this.$forceUpdate()
    //});    

  },
  methods: {
    async getUsersTokens(force = false) {

      this.yourTokens = []

      if (force) {
        await PA.Core.Proof.refresh({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id
        })
      }

      if (this.user) {
        this.yourTokens = await PA.Core.Proof.getProofs({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
        })
        this.yourTokens = this.yourTokens.sort((a, b) => { return parseInt(a.nftId) - parseInt(b.nftId) })
        this.yourTokens.forEach((token) => {
          token.meta = token.meta || {}
        })
      }
    },
   async getSharedTokens(force = false) {

      this.sharedTokens = []

      if (force) {
        await PA.Core.Proof.refresh({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id
        })
      }

      if (this.user) {
        this.sharedTokens = await PA.Core.Proof.getSharedProofs({
          collectionId: this.proofGroup.site,
          proofGroupId: this.proofGroup.id,
        })
        this.sharedTokens = this.sharedTokens.sort((a, b) => { return parseInt(a.nftId) - parseInt(b.nftId) })
        this.sharedTokens.forEach((token) => {
          token.meta = token.meta || {}
        })
      }
    },    
    selectGallery(item) {

      this.$router.push({
        name: "editGallery", params: {
          site: this.proofGroup.site,
          proofGroup: this.proofGroup.id,
          tokenId: item.nftId
        }
      })


    },

  }
};
</script>

<style lang="scss">
/*
.v-navigation-drawer__content::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
.v-navigation-drawer__content::-webkit-scrollbar{
  width: 10px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
  border-radius: 5px;
}
*/

.theme--light .theme--light.v-list.slideimage {
  background-color: #FAA471;
  background-image: url("/logo-line.png");
  background-repeat: repeat-y;
  border-top: #d8763e 1px solid;
}

.characterPreviewx {
  overflow-y: scroll;
  position: fixed;
  bottom: 50px;
  top: 140px;
  left: 20px;
  right: 20px;
  border: 10px solid #666;
  border-radius: 6px;

  &.user {
    left: 300px
  }
}
</style>
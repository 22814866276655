<template>
    <v-card class="loginDlg" width="500" flat>
        <v-card-text>
          <div>
            <v-img width="40px" class="metaLogo mx-auto mt-3" alt="Metamask Logo" src="/metamask.png" />
            <div class="text-center mb-4">
              <v-btn class="mb-3" @click="web3Login()">Connect your Metamask Wallet</v-btn>
            </div>
            <v-divider></v-divider>
          </div>
          <div>
            <v-sheet
              min-height="50vh"
              rounded="lg"
              class="mt-4"
            >
              <div class="authbox">
                <section v-if="!redirect" id="firebaseui-auth-container"></section>
                <div v-if="redirect">
                  <h3 class="text-center my-8">Logging you in, please wait</h3>
                  <v-progress-linear
                  color="grey accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
                </div>
              </div>
            </v-sheet>
            </div>
          <div class="terms mt-3" >
            By logging in, or connecting your wallet you agree to the <a href="/terms" target=_blank>Terms and Conditions</a> of this platform
          </div>
        </v-card-text>
    </v-card>
</template>

<script>

//const Web3 = require('web3');
//import Api from "@/api"
import PA from '@proveanything/base'


import "@proveanything/auth/firebaseui.css"


export default {
  components: {
  },
  props: {
    chain: String,
    useWeb3: Boolean,
    hideEmail: Boolean

  },
  data: () => ({
    loader: true,
    redirect: false,
  }),
  async mounted() {
//      console.log("has a login?", PA.Auth.User.isValid(), PA.Auth.User.id())

      PA.Auth.UI.init({
          id: "#firebaseui-auth-container",
          disableSignUp: false,
          adminEmail: "help@galleryland.xyz",
          domain: "editor.galleryland.xyz",
          onSuccess: this.loginSuccess
      })


  },  
  methods: {
    async loginSuccess(){
//        console.log("has a login?", PA.Auth.User.isValid(), PA.Auth.User.name(),  PA.Auth.User.id())
    },
    async web3Login() {

        PA.Ethereum.Web3.login({
            welcomeMessage: "Welcome to GalleryLand",
            onSuccess: this.loginSuccess
        })

    }

  },

}

</script>
<style lang="scss" scoped>
.loginDlg {
  margin: 150px auto;
}

</style>
